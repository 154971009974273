<template>
  <div></div>
</template>

<script>
/* eslint-disable */
import AuthService from "../../../services/auth/AuthService";
export default {
  mounted() {
    AuthService.logout();
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
